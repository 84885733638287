export default {
  topBar: {
    userInterface: "Interface usager",
    myAccount: "Mon compte",
    signOut: "Déconnexion",
    myAuthCode: "Mon code d'authentification",
    myReport: "Mes rapports automatiques",
  },
  menu: {
    dashboard: "Dashboard",
    items: {
      title: "Véhicules",
      list: "Liste des véhicules",
      add: "Ajouter un véhicule",
      groups: "Groupes de véhicules",
      rtmap: "Carte temps-réel",
      trips: "Journal des trajets",
      itemReturnAlerts: "Alertes de non-retour",
      itemMovementAlerts: "Alertes de non-mouvement",
      rollingLaws: "Lois de roulage",
      itemInterventions: "Journal des interventions",
      itemIncidents: "Journal des incidents",
      itemIndicatorsAlerts: "Alertes voyants",
    },
    accesses: {
      title: "Accès",
      list: "Liste des accès",
      add: "Ajouter un accès",
    },
    bookings: {
      title: "Réservations",
      list: "Liste des réservations",
      calendar: "Calendrier",
      no_items_certs: "Certificats de non-disponibilités",
      restrictions: "Restrictions",
      validations: "Validations",
      projects: "Projets/Ordres de mission",
    },
    users: {
      title: "Usagers",
      list: "Liste des usagers",
      add: "Ajouter un usager",
      groups: "Groupes d'usager",
    },
    itemsuses: {
      title: "Statistiques",
      dashboard: "Dashboard des utilisations",
      history: "Historique des utilisations",
      chronogram: "Chronogramme",
    },
    settings: {
      title: "Paramètres",
      sites: "Sites",
      kboxs: "KBoxs",
      pricingGroups: "Tarifications des usages privés",
      brands: "Marques",
      colors: "Couleurs",
      itemsOrdering: "Ordonnancement des véhicules",
      updateNotes: "Notes de mise à jour",
    },
  },
};
